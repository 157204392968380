.aboutus-txt {
    font-size: 20px;
}

.aboutus-subheading {
    font-size: 25px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 15px;
}

.section-abtus {
    margin: 10px 0;
}