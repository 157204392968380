.data-card {
    width: 96%;
    margin: 10px auto;
    display: grid;
    grid-template-columns: 4% 93%;
    color: #2d852f;
}

.data-image-card-container {
    background-color: #D11A2A;
}

.child-card-image {
    width: 60px;
}

.data-container-data{
    margin: auto 15px;
}

.data-card-body-child, .data-card-body-parent {
    text-align: center;
    display: inline-flex;
    margin: auto;
}

.card-name-content, .card-age-content,
.card-parent-name-content, .card-parent-email-content, .card-parent-num-content {
    margin: 0;
}

.data-card-body-parent, .data-card-body-parent, .photo-consent-container {
    margin: auto;
}

.add-container, .med-container {
    display: block;
    margin: auto 0 auto 15px;
}

.card-name, .card-age, .card-email, .card-number {
    margin: auto;
}

.card-photo-content {
    font-weight: bold;
    font-size: 20px;
    margin: auto;
}

.price-card {
    width: 60%;
    margin-bottom: 20px;
}

.data-card-price {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 24px;
}



@media only screen and (max-width: 760px) {
    .price-card {
        width: 96%;
    }

    .child-card-image {
        width: 40px;
    }

    .data-card-body-parent, .data-card-body-parent, .card-name, .card-age {
        font-size: 12px
    }

    .card-name-content, .card-age-content,
    .card-parent-name-content, .card-parent-email-content, .card-parent-num-content {
        font-size: 12px;
    }

    .data-card-price {
        font-size: 20px;
    }
}

@media only screen and (max-width: 550px) {
    .data-container-data {
        display: inline-flex;
    }

    .summary-container, .checkout-container {
        margin: 3% 0.5%;
    }

    .data-card-body-child, .data-card-body-parent {
        text-align: left;
        margin: auto 0 auto 20px;
    }

    .data-card-body-parent {
        display: block;
    }

    .card-name, .card-email, .card-number, .card-age {
        margin-right: 5px;
    }

    .photo-consent-container {
        text-align: center;
    }

    .card-photo-content {
        font-size: 18px;
    }

    .price-container {
        text-align: center;
    }
}