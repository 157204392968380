.gallery-page {
    padding-bottom: 0.1%;
}

.gallery-page-container {
    color: #fff6d2;
    background-color: rgba(3, 61, 32, 0.8);
    text-align: center;
    border-radius: 15px;
    border: 5px solid #fff6d2;
    padding: 1% 0.5%;
    margin: 1% 0.5%;
}

.gallery-container {
}

@media only screen and (max-width: 1798px) {
    .gallery-container {
        /*display: grid;*/
        /*grid-template-columns: repeat(2, 1fr);*/
    }
}

@media only screen and (max-width: 1044px) {
    .gallery-container {
        /*display: grid;*/
        /*grid-template-columns: repeat(1, 1fr);*/
    }
}

@media only screen and (max-width: 550px) {
    .gallery-page-container {
        border: 3px solid #fff6d2;
        margin: 3% 1.5%;
    }

    .gallery-page-title {
        margin-top: 15px;
    }
}
