.notification-container {
    width: 98%;
    position: absolute;
    z-index: 100;
}

.fade {
    animation: notification-container 0.5s 1;
    -webkit-animation: notification-container 0.5s 1;
    animation-fill-mode: both;
    animation-delay: 2s;
    -webkit-animation-delay: 1s; /* Safari and Chrome */
    -webkit-animation-fill-mode: both;
}

.form-notification {
    position: relative;
    width: fit-content;
    margin: 0 auto 10px;
}

.form-error {
    border: 1px solid red;
    background-color: #fff !important;
    color: red !important;
    height: fit-content !important;
}

.form-error-text {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

@keyframes notification-container{
    from {opacity :1;}
    to {opacity :0;}
}

@-webkit-keyframes notification-container{
    from {opacity :1;}
    to {opacity :0;}
}

.notification-text-container {
    width: 100%;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    height: 61px;
}

.notification-text-container h3 {
    padding: 12px 20px
}

.success {
    background-color: #198754;
}

.error {
    background-color: #cc0000;
}

@media only screen and (max-width: 550px) {
    .notification-container {
        padding-right: 2%;
    }

    .notification-text-container {
        height: 47px;
    }

    .notification-text-container h3 {
        padding: 8px 20px;
    }

    .form-error-text {
        font-size: 14px;
        font-weight: bold;;
    }
}

