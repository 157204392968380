.app-navigation {
    background-color: #02572d;
    height: 64px;
    display: grid;
}

.nav-option-container {
    text-align: center;
}

.menu-options {
    list-style-type: none;
}

.nav-link {
    float: left;
    color: #fff6d2;
    font-size: 22px;
    justify-content: center;
    margin-top: 8px;
}

.nav-link:hover {
    font-weight: bold;
    color: #f1e5c0 !important;
}

.logo-container {
    float: right;
    position: absolute;
    right: 0;
    margin: 16px 10px 0 0;
}

.logo-img{
    width: 44px;
    margin: -6px 0  0 0;
}

.active {
    color: #f1e5c0 !important;
    font-weight: bold;
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
}

.app-mobile-nav {
    display: none;
}

@media only screen and (max-width: 750px) {
    .app-navigation {
        position: relative;
        min-height: 64px;
    }

    .logo-container {
        z-index: 1000;
        margin-top: 16px;
    }

    .nav-options-container {
        display: none;
    }

    .app-mobile-nav {
        width: 100%;
        display: block;
        position: relative;
        z-index: 1;
        padding: 21px 10px;
        height: 64px;
        background-color: #02572d;
    }

    .app-mobile-nav a {
        color: #f1e5c0;
        transition: color 0.3s ease;
    }

    .app-mobile-nav input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: 16px;
        left: 6px;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;
    }

    .app-mobile-nav span  {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: #f1e5c0;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
    }

    .app-mobile-nav span:first-child {
        transform-origin: 0 0;
    }

    .app-mobile-nav span:nth-last-child(2) {
        transform-origin: 0 100%;
    }

    .app-mobile-nav input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #f1e5c0;
    }

    .app-mobile-nav input:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    .app-mobile-nav input:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
    }

    .menu {
        position: absolute;
        width: 100%;
        margin: -110px 0 0 -10px;
        padding: 125px 50px 50px;
        background: #02572d;
        list-style-type: none;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0 0;
        transform: translate(-100%, 0);
        transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0);
    }

    .nav-link, .nav-link:last-of-type {
        padding: 10px 0;
        font-size: 22px;
        float: none;
    }

    .active {
        font-size: 28px;
        font-weight: bold;
    }

    .app-mobile-nav input:checked ~ ul {
        transform: none;
    }
}