.app-page {
    background-image: url("../assets/static/imgs/jungle-background.jpg");
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 100vh;
    height: 100%;
 }

.page-sub-header {
    margin-top: -15px;
}

.back-arrow-app-container {
    margin-top: 10px;
    font-size: 22px;
    display: inline-flex;
}

.back-arrow-app {
    font-size: 30px;
    margin-right: 10px;
}

.back-arrow-app-text {
    font-size: 22px;
}

.back-arrow-app-container:hover {
    font-size: 45px;
    color: #c2fccc;
    cursor: pointer;
}

@media only screen and (max-width: 550px) {
    .back-arrow-app-container {
        
    }
}