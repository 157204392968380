.button-link {
    background-color: #296827;
    border-radius: 15px;
    padding: 10px 50px;
    color: #fff;
    border: #fff solid 2px;
    margin: 5px;
    font-size: 18px;
}

.button-link:hover {
    background-color: #968044;
    font-size: 19px;
}

.disabled {
    background-color: #3b483a;
}

.disabled:hover {
    background-color: #3b483a;
    font-size: 18px;
}

@media only screen and (max-width: 701px) {
    .button-container {
        display: grid;
        }
}