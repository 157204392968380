.function-bar {
    background-color: #02572d;
    height: 64px;
    display: grid;
    margin-left: 63px;
}

.app-header {
    background-color: #02572d;
    height: 64px;
    display: grid;
}

.round-out-inner-corner {
    position: absolute;
    height: 66px;
    width: 66px;
    background-color: #02572d;
    margin-left: -64px;
    border-radius: 5px;
}

.logo {
    margin: 17px 0 0 10px;
    z-index: 1006;
}

.logo-text {
    color: #fff;
    font-size: 24px;
    margin-top: 1px;
}

.logo-text::before {
    content: 'RepeatForKids ';
}

.action-icons {
    position: absolute;
    right: 1%;
    margin-top: 2px;
    z-index: 10000;
}

.to-site-icon {
    position: absolute;
    right: 60px;
    font-size: 28px !important;
    margin-top: 9px;
    color: #fff;
    z-index: 10000;
}

.to-site-icon:hover {
    color: #d5f5e8;
}

@media only screen and (max-width: 900px) {
    .action-icons {
        position: absolute;
        right: 3%;
        margin-top: 2px;
    }

    .to-site-icon {
        position: absolute;
        right: 60px;
        font-size: 28px !important;
        margin-top: 9px;
        color: #fff;
    }
}

@media only screen and (max-width: 550px) {
    .function-bar {
        width: 100%;
        position: absolute;
        display: inline-flex;
        top: 0;
        margin-left: 0;
    }

    .logo-text::before {
        content: none;
    }

    .round-out-inner-corner {
        display: none;
    }

    .logo {
        margin: 10px auto;
    }

    .logo-text {
        margin-top: 10px;
    }

    .logout {
        right: 5px;
    }

}