.broker-page-checkout-container {
    color: #fff6d2;
    text-align: center;
    margin: 20px 0.5%;
}

.checkout-page-container {
    display: grid;
    grid-template-columns: 40% 60%;
}

.summary-container, .checkout-container, .checkout-sub-header {
    background-color: rgba(3, 61, 32, 0.8);
    margin: 0 1%;
    border-radius: 15px;
    border: 5px solid #fff6d2;
}

.checkout-container {
    height: fit-content;
}

.summary-content {
    width: 100%;
}

.summary-sub-title {
    font-size: 18px;
    float: left;
    margin: 10px 2% 5px;
}

.checkout-sub-header {
    margin: 0.5%;
    font-weight: bold;
}

.checkout-title {
    margin: 20px 0 40px;
    text-decoration-line: underline;
}

.booking-info-summary {
    /*width: 80%;*/
}

.paypal-container {
    margin: 0 2%;
}

.to-pay {
    margin-top: -20px;
    margin-bottom: 20px;
}

/*@media only screen and (max-width: 1950px) {*/
/*    .checkout-page-container {*/
/*        grid-template-columns: 50% 50%;*/
/*    }*/
/*}*/

@media only screen and (max-width: 1800px) {
    .checkout-page-container {
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: 1400px) {
    .checkout-page-container {
        grid-template-columns: 60% 40%;
    }
}

@media only screen and (max-width: 1150px) {
    .checkout-page-container {
        display: block;
    }

    .summary-container, .checkout-sub-header {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 950px) {
    .checkout-page-container {
        display: block;
    }

    .summary-container {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 550px) {
    .broker-page-checkout-container {
        margin: 20px 0.5%;
    }

    .summary-container, .checkout-container, .checkout-sub-header {
        border: 3px solid #fff6d2;
        margin: 3% 0.5%;
    }

    .back-arrow-app-text {
        margin-bottom: 0;
        padding: 0;
    }

    .checkout-title {
        margin: 20px 0;
    }

    .to-pay {
        margin-top: 0;
    }
}