.navigation {
    min-height: 100%;
    position: absolute;
}

/* NAVBAR CONTAINER */
.sidenav---sidenav---_2tBP {
    background-color: #02572d;
    min-height: fit-content;
    padding-bottom: 128px;
    height: 100%;
}

/* HAMBURGER BUTTON */
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    color: #fff;
    opacity: unset;
}

.sidenav---sidenav-toggle---1KRjR:hover {
    background-color: #033d20;
}

/* NAV OPTION ICON */
a .sidenav---navicon---3gCRo {
    display: block;
    float: left;
    width: 64px;
    height: 50px;
    margin-right: 0;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 20px;
    background-color: transparent;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
    line-height: 50px;
    text-align: center;
}

/* NAV OPTION TEXT */
a .sidenav---sidenav-navitem---uwIJ- {
    clear: both;
    position: relative;
}

a .sidenav---sidenav-navitem---uwIJ-:hover {
    background-color: #033d20;
}

a .sidenav---navitem---9uL5T {
    position: relative;
    display: block;
    line-height: 57px;
    height: 50px;
    white-space: nowrap;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

a {
    text-decoration: none;
}

.nav-text {
    transition: visibility 0s 0.2s, opacity 0.2s linear;
    display: none;
    text-decoration-line: none;
}

.active .sidenav---sidenav-navitem---uwIJ-{
    background-color: #033d20;
}

.mobile-nav {
    display: none;
}

/* MOBILE NAV */
@media only screen and (max-width: 550px) {
    .navigation {
        position: relative;
        min-height: 64px;
    }
    .sidenav---sidenav---_2tBP {
        display: none;
    }
    .navbar-inner {
        display: none;
    }

    .mobile-nav {
        width: 100%;
        display: block;
        position: relative;
        z-index: 1;
        padding: 21px 10px;
        height: 64px;
        background-color: #02572d;
    }

    .mobile-nav a {
        color: #fff;
        transition: color 0.3s ease !important;
    }

    .mobile-nav input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: 16px;
        left: 6px;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;
    }

    .mobile-nav span  {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: #fff !important;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
    }

    .mobile-nav span:first-child {
        transform-origin: 0 0;
    }

    .mobile-nav span:nth-last-child(2) {
        transform-origin: 0 100%;
    }

    .mobile-nav input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #fff !important;
    }

    .mobile-nav input:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    .mobile-nav input:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
    }

    .menu {
        position: absolute;
        width: 100%;
        margin: -110px 0 0 -10px;
        padding: 125px 50px 50px;
        background: #02572d;
        list-style-type: none;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0 0;
        transform: translate(-100%, 0);
        transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0);
    }

    .nav-link {
        padding: 10px 0;
        font-size: 22px;
    }

    .active {
        font-size: 28px;
        font-weight: bold;
    }

    .mobile-nav input:checked ~ ul {
        transform: none;
    }
}