.dashboard-page-content {
    background-color: unset;
    padding: 1% 0;
    margin-left: 0;
    margin-top: -20px;
}

.action-tiles-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.action-tile {
    text-align: center;
    color: #02572d;
    background-color: #fff;
    border-radius: 5px;
    margin: 20px;
    font-size: 70px;
}

.action-tile:hover {
    background-color: #d5f5e8;
}

.action-tile > a {
    color: #02572d;
}

.action-tile > h3 {
    font-size: 24px;
    margin: -10px 0 18px;
    padding-bottom: 10px;

}

.tile-icon {
    margin-top: -16px;
    border: 5px solid #02572d;
    border-radius: 5px;
    padding: 5px;
}

.page-sub-header {
    padding-top: 15px;
    margin-left: .6%;
}

.recent-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.recent-container {
    background-color: #fff;
    border-radius: 5px;
    font-size: 70px;
    margin: 0 1% 15px;
    padding-left: 10px;
    margin-left: 0;
    padding-top: 10px;
}

.recent-page-content {
    font-size: 14px;
}

@media only screen and (max-width: 1300px) {

    .action-tiles-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .action-tile {
        margin: 20px;
    }

    .recent-section {
        display: grid;
        grid-template-columns: 1fr;
    }

}

@media only screen and (max-width: 880px) {

    .action-tiles-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .recent-section {
        display: block;
        grid-template-columns: 1fr;
        text-align: center;
    }

    .recent-container {
        margin: 30px 0;
    }

    .recent-page-content {
        margin-left: -10px;
    }

}

@media only screen and (max-width: 550px) {

    .dashboard-page-content {
        margin-top: -14px;
    }

    .page-heading {
        margin-left: 0;
    }

    .action-tiles-container {
        display: none;
    }

}