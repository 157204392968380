.page-header {
    background-color: #fff;
    border-radius: 5px;
    padding: 0 2% 1px;
    margin-bottom: 8px;
    text-align: center;
    position: relative;
}

.heading-container {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0 10px 5px;
    text-align: center;
}

.page-header p {
    margin-left: 7px;
    text-decoration-line: underline;
    color: blue;
    margin-bottom: 0;
}

.page-heading {
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: -3px;
}

.back-arrow-container {
    position: absolute;
    margin-top: 6px;
    z-index: 1000;
}

.back-arrow {
    font-size: 50px;
    display: inline-block;
}

.back-arrow:hover {
    font-size: 55px;
    cursor: pointer;
}

@media only screen and (max-width: 550px) {
    .back-arrow-container {
        margin: 0 ;
    }
}