.option-card {
    width: 97%;
    margin: 10px auto;
    flex-grow: 1;
    align-self: flex-start;
    display: inline-block;
    color: #2d852f;
}

.white {
    background-color: #fff;
}

.white:hover {
    cursor: pointer;
    background-color: #fff6d2;
}

.grey {
    background-color: #d9d9d9;
}

.photo-credit {
    position: absolute;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
    border-bottom-right-radius: calc(0.25rem - 1px);
}

.card-body > a {
    z-index: 100;
}

.card-title {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: bold;
}

.card-section-title {
    margin-bottom: -3px
}

.booking-status {
    margin-top: -10px;
    margin-bottom: 10px;
}

.spaces-available {
    background-color: #D7FCDD;
    font-size: 12px;
    padding: 5px 16px;
    border-radius: 20px;
    margin-right: 11px;
    font-weight: bold;
}

.spaces-unavailable {
    background-color: #ff9898;
    font-size: 12px;
    padding: 5px 16px;
    border-radius: 20px;
    margin-right: 11px;
    color: #cf2525;
    font-weight: bold;
}

h2 {
    margin: auto;
}

.card-image-container {
    display: flex;
    float: left;
}

.card-image {
    width: 293px;
}

.data-container {
    margin-bottom: 32px;
}

.final-container  {
    margin-bottom: 0 !important;
}

.date-lbl {
    font-weight: bold;
}

.date {
    color: #000;
    margin-top: -12px;
}

.date-container {
    margin-top: 30px;
}

.card-lbl {
    font-weight: bold;
}

.card-data-text {
    color: #000;
    margin-top: -12px;
}

.card-price {
    font-size: 20px;
}

@media only screen and (max-width: 1798px) {
    .card-image {
        width: 245px;
    }

    .card-body {
        margin: 5px 0 0 0;
        padding: 0.5rem 0.5rem 0 0.5rem;
    }

    .data-container {
        margin-bottom: 23px;
    }

    .card-price {
        font-size: 16px;
    }
}

@media only screen and (max-width: 550px) {

    .card-image {
        width: 175px;
    }

    .card-body {
        margin: 15px 0 0 0;
        padding: 0;
    }

    .booking-status {
        margin: 0 0 21px 0;
    }

    .data-container {
        margin-bottom: -12px;
    }

    .card-title {
        font-size: 14px;
    }

    .spaces-available, .spaces-unavailable {
        font-size: 10px;
    }

    .card-lbl, .card-data-text {
        font-size: 13px;
    }

    .card-price {
        font-size: 14px;
    }
}