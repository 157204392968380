.login-title {
    margin-bottom: 25px;
}

.login-card {
    color: #fff;
    background-color: #02572d;
    border: 1px solid #fff !important;
    border-radius: 5px !important;
}

.login-card .form-floating {
    color: #000;
}

.login-btn {
    height: 50px;
    background-color: #efefef;
}

.login-btn:hover {
    background-color: #b9e7b4;
    color: #fff;
    width: 100%;
}

.login-error-container {
    padding: 0;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 15px;
    border-radius: 5px;
    text-align: center;
}

.login-error-container > p {
    margin: 0 2px;
    padding: 0;
}