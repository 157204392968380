.form-buttons {
    display: block;
    position: relative;
    height: 60px;
}

.save-buttons-container, .delete-button-container,
.reinstate-button-container, .remove-button-container{
    display: inline-block;
    position: absolute;
}

.save-buttons-container, .reinstate-button-container {
    width: 86%;
    margin-right: 14%;
}

.delete-button-container, .remove-button-container {
    text-align: right;
    margin-left: 86%;
    width: 14%;
}

.save-button, .reinstate-button {
    margin-top: 20px;
    background-color: #008000 !important;
    border-color: #008000 !important;
    margin-right: 20px;
}

.delete-button, .remove-button {
    margin-top: 20px;
    background-color: #D11A2A !important;
    border-color: #D11A2A !important;
}

.del-all-btn {
    border-color: #D11A2A !important;
    background-color: #D11A2A;
    margin-right: 20px;
    float: right;
    margin-top: 0;
}

.del-all-btn:hover {
    border-color: #D11A2A !important;
    background-color: #D11A2A;
}

.booking-del {
    margin: 10px 0;
}

.event_form div input, .event_form div textarea, .event_form div select,
.session_form div input, .session_form div textarea, .session_form div select,
.image_form div input, .image_form div textarea, .image_form div select,
.message_form div input, .message_form div textarea,
.booking_form div input, .booking_form div textarea{
    margin-bottom: 10px;
}

#form-event_reserved, #form-event_date, #form-event_time, #form-event_max, #form-event_price,
#form-session_reserved, #form-session_date, #form-session_time, #form-session_max, #form-session_price,
#form-image_order
{
    width: fit-content;
}

#form-event_image, #form-session_image, #form-image_image, #form-video_thumbnail, #form-event_logo {
    width: 350px;
}

.error-notification {
    color: red;
    border-color: red;
}

.new-event-button, .new-session-button, .new-image-button, .new-tag-button, .new-product-button {
    border-color: #008000 !important;
    background-color: #008000;
    margin-right: 20px;
}

.thumbnail-container {
    width: 300px;
}

.no-img {
    font-size: 14px;
}

@media only screen and (max-width: 550px) {
    #form-event_price, #form-event_date, #form-event_time, #form-event_reserved, #form-event_max, #form-event_image,
    #form-session_reserved, #form-session_date, #form-session_time, #form-session_max, #form-session_price, #form-session_image,
    #form-image_order, #form-image_image, #form-booking_parent_email, #form-booking_child_age_1, #form-booking_child_age_2,
    #form-booking_child_age_3, #form-booking_child_age_4, #form-booking_child_age_5, #form-booking_child_num, #form-booking_photo_consent,
    #form-booking_completed, #form-booking_parent_contact
    {
        width: 100%;
    }

    .delete-button-container, .remove-button-container {
        margin-left: 77%;
        width: 23%;
    }

    .new-event-button, .new-session-button, .new-image-button{
        /*margin-bottom: 15px;*/
        /*width: 100%;*/
        /*position: relative;*/
        /*bottom: 0;*/
        float: left;
        font-size: 14px;
    }

    .del-all-btn {
        /*margin-bottom: 60px;*/
        /*width: 98%;*/
        /*position: relative;*/
        /*bottom: 0;*/
        float: right;
        font-size: 14px;
    }

    .thumbnail-container {
        width: 150px;
    }
}

