.broker-page {
    padding-bottom: 0.1%;
}

.broker-page-container {
    color: #fff6d2;
    background-color: rgba(3, 61, 32, 0.8);
    text-align: center;
    border-radius: 15px;
    border: 5px solid #fff6d2;
    padding: 1% 0.5%;
    margin: 20px 0.5%;
}

.broker-container-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.no-options-container {
    margin: 30px 0;
}

/* form css */
.form-container {
    width: 950px;
    margin: 1% auto;
}

@media only screen and (max-width: 1798px) {
    .broker-container-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 1044px) {
    .broker-container-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .form-container {
        width: 98%;
    }
}

@media only screen and (max-width: 550px) {
    .broker-page-container {
        border: 3px solid #fff6d2;
        margin: 3% 1.5%;
    }

    .broker-page-title {
        margin-top: 15px;
    }
}
