#form-child_name_1, #form-child_age_1, #form-child_name_2, #form-child_age_2, #form-child_name_3, #form-child_age_3,
#form-child_name_4, #form-child_age_4, #form-child_name_5, #form-child_age_5, #form-child_name_3, #form-child_age_4,
#form-child_num, #form-child_med_info, #form-child_add_info, #form-child_parent_name, #form-child_parent_email,
#form-child_parent_number{
    text-align: center;
    margin-bottom: 15px !important;
}

.data-form-button {
    width: 100%;
    display: grid;
}

.form-notice {
    margin-top: 6px;
    font-size: 21px;
    margin-bottom: 10px;
}

.form-child_photo_consent {
    margin: 15px 0 15px 0;
    display: grid;
}

.form-child_one_2_one {
    margin-bottom: 15px;
}

.display-only-card {
    width: 96% !important;
}

.display-only-card:hover {
    background-color: #fff !important;
    cursor: default;
}

.form-notification {
    margin: 10px auto;
    background-color: #fff6d2;
    color: #d11a2a;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;

}

@media only screen and (max-width: 1044px) {
    .display-only-card {
        width: 96% !important;
    }
}

@media only screen and (max-width: 1044px) {
    .form-child-num, .form-name, .form-age, .form-med-info, .form-add-info, .form-parent-name, .form-email,
    .form-number {
        width: 90%;
    }
}