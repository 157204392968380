html, body {
    height: 100%;
}



.admin-page {
    background-color: #efefef;
    height: 100%;
}

.sidenav---sidenav---_2tBP, .sidenav---collapsed---LQDEv {
    min-height: 100%;
}