.home-page {
    height: 100%;
}

.home-page-container {
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff6d2;
    background-color: rgba(3, 61, 32, 0.8);
    text-align: center;
    border-radius: 15px;
    padding: 1%;
    border: 5px solid #fff6d2;
}

.home-title {
    font-size: 70px ;
    opacity: 1;
}

.home-subheading {
    font-size: 30px;
}

.home-buttons {
    display: inline-flex;
    margin-top: 30px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1030px) {
    .home-buttons {
        display: block;
    }
    .button-container {
        display: grid;
    }
}

@media only screen and (max-width: 700px) {
    .home-page-container {
        width: 98%;
    }
    .home-buttons {
        display: grid;
    }
}

