@import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Varela Round', sans-serif;
}

html, body, #root {
  height:100%;
}

.App {
  min-height: 100%;
  position: relative;
}

.page-container {
  position: relative;
  background-color: #efefef;
  margin-left: 64px;
  min-height: calc(100vh - 64px);
  padding: 1%;
  transition: margin-left;
  transition-duration: 0.2s;
  display: flex;
  flex-direction: column;
}

.page-content {
  background-color: #fff;
  padding: 1% 1% 0;
  border-radius: 5px;
  flex: 1 1 auto;
}

.sub-heading {
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.container {
  padding-bottom: 20px;
}

.broker-page-title {
  text-decoration: underline;
}

a:hover {
  color: #fff !important;
}

@media only screen and (max-width: 550px) {
  .page-container {
    margin-left: 0 !important;
    padding: 2%;
  }

  .sub-heading {
    margin-top: 10px;
  }

  .save-heading {
    text-align: center;
  }
}
