.booking-conf-page {
    height: 100%;
}

.booking-conf-page-container {
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff6d2;
    background-color: rgba(3, 61, 32, 0.8);
    text-align: center;
    border-radius: 15px;
    padding: 1%;
    border: 5px solid #fff6d2;
}

.booking-conf-title {
    font-size: 60px ;
    opacity: 1;
}

.booking-conf-subheading {
    font-size: 26px;
    margin: 20px 0 10px 0;
}

@media only screen and (max-width: 700px) {
    .booking-conf-page-container {
        width: 98%;
    }

    .booking-conf-title {
        font-size: 50px ;
        opacity: 1;
    }
 }

