.data-table-container ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000 !important;
    opacity: 1 !important; /* Firefox */
}

.data-table-container:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000 !important;
}

.data-table-container::-ms-input-placeholder { /* Microsoft Edge */
    color: #000 !important;
}

.data-table-container {
    background-color: #c2f3df;
    border-radius: 5px;
    color: #000;
}

.fSQziN, .csxlVi {
    border-radius: 0 !important;
}

.filter-text {
    color: #000;
}