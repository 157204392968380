.toggle-group {
    width: 100%;
    margin: auto;
}

.option-container {
    width: 100%
}

.btn-toggle {
    background-color: #fff;
    width: 100%;
    border-radius: 3px;
    font-weight: bold;
}

.btn-green {
    border: 2px solid #008000;
    color: #008000;
}

.btn-red {
    border: 2px solid #D11A2A;
    color: #D11A2A;
}

.btn-toggle:hover, .green-checked, .red-checked {
    border: 2px solid #fff;
    cursor: pointer;
    color: #fff;
}

.btn-green:hover, .green-checked {
    background-color: #008000;
}

.btn-red:hover, .red-checked {
    background-color: #D11A2A;
}

.green-checked {

}
